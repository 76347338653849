import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { DialogModule } from '@shared/component/common/dialog/dialog.module';
import { dateFormat, AppMomentDateAdaptor } from '@shared/utils/moment';
import { GlobalErrorHandler } from '@shared/services/global-error-handler.service';
import { SharedModule } from '@shared/shared.module';
import { ErrorModule } from './shells/error/error.module';
import { ShellModule } from './shells/shell/shell.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LazyLoadFailDialogModule } from '@shared/component/common/lazy-load-fail-dialog/lazy-load-fail-dialog.module';
import {
  MatDialogConfig,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from '@shared/route-reuse-strategies/cache-route-reuse-strategy';

const defaultDialogOptions: MatDialogConfig = (() => {
  const config = new MatDialogConfig();
  config.maxWidth = 'calc(100vw - 3rem)';
  config.maxHeight = 'calc(100vh - 3rem)';
  return config;
})();

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot(),
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    DialogModule,
    ShellModule,
    ErrorModule,
    MatProgressBarModule,
    LazyLoadFailDialogModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MAT_DATE_FORMATS, useValue: dateFormat },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: defaultDialogOptions },
    {
      provide: DateAdapter,
      useClass: AppMomentDateAdaptor,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
