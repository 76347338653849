import {
  FAndBTradeCategoryGroupID,
  IBuilding,
  ICodeDescription,
  IColorCodeDescription,
  IEditableBuilding,
  IEditableLease,
  ILease,
  ILeaseWithPeriod,
  ILocalizedString,
  LeaseStatus,
} from '@model/common';
import { BuildingCommonFacility } from '@model/common/BuildingCommonFacility';
import {
  BuildingFacility,
  FacilityTypeStatus,
} from '@model/common/BuildingFacility';
import { BuildingFloor } from '@model/common/BuildingFloor';
import { FieldState } from '@model/common/FieldState';
import {
  IAuthSubmissionGroup,
  IAuthSubmissionGroupRole,
  IAuthUserSubmissionGroup,
  IRole,
} from '@model/user';
import {
  IAPIAuthSubmissionGroup,
  IAPIAuthSubmissionGroupRole,
  IAPIRole,
} from '@service/api/models/auth';
import {
  APIBuildingCommonFacilityDTO,
  APIBuildingFacilityDTO,
  APIFieldState,
  IAPIBuilding,
  IAPIEditableBuilding,
  IAPILease,
  IAPILeaseWithPeriod,
} from '@service/api/models/common';
import { isNonNullable } from '@shared/utils/prelude';

export function mapBuilding(building: IAPIBuilding): IBuilding {
  return { id: building.buildingID, name: building.localBuildingName };
}

export function mapLease(lease: IAPILease): ILease {
  return {
    leaseID: lease.leaseID,
    leaseIdentifier: lease.leaseIdentifier,
    leaseNo: lease.leaseNo,
    buildingID: lease.building.buildingID,
    buildingName: lease.building.localBuildingName,
    tenantName: lease.currentTenantName,
    shopName: lease.currentShopList || null,
    unitName: lease.unitName,
    portfolioType: lease.portfolioType,
    tenancyType: (() => {
      if (lease.portfolioType === 'O') {
        return 'office';
      }
      if (lease.portfolioType === 'R') {
        return lease.tradeCategoryGroupID === FAndBTradeCategoryGroupID
          ? 'f-and-b'
          : 'retail';
      }
      return 'other';
    })(),
    status: lease.status as LeaseStatus,
    additionalInfo: lease.additionalInfo,
  };
}

export function mapLeaseWithPeriod(
  lease: IAPILeaseWithPeriod
): ILeaseWithPeriod {
  return {
    ...mapLease(lease),
    commencementDate: lease.commencementDate,
    expiryDate: lease.expiryDate,
  };
}

export function mapEditableLease(lease: IAPILease): IEditableLease {
  return {
    ...mapLease(lease),
    editable: lease.editable,
  };
}

export function mapEditableBuilding(
  building: IAPIEditableBuilding
): IEditableBuilding {
  return {
    ...mapBuilding(building),
    isEditable: building.isEditable,
  };
}

interface IAPIDescription {
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
}
function isIAPIDescription(
  d: IAPIDescription | ICodeDescription
): d is IAPIDescription {
  return typeof d.description === 'string';
}
export function mapDescription(
  desc: IAPIDescription | ICodeDescription
): ILocalizedString {
  if (isIAPIDescription(desc)) {
    return {
      enUS: desc.description,
      zhHant: desc.descriptionZhHK,
      zhHans: desc.descriptionZhCN,
    };
  }
  return desc.description;
}

export interface IAPICodeDescription {
  tpShow: boolean;
  active: boolean;
  code: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
  sequence: number;
}

export function mapCodeDescription(
  desc: IAPICodeDescription | ICodeDescription
): ICodeDescription {
  return {
    code: desc.code,
    description: mapDescription(desc),
  };
}

export function mapCodeDescriptions(
  desc: IAPICodeDescription[]
): ICodeDescription[] {
  return desc
    .filter((d) => d.tpShow && d.active)
    .sort((a, b) => a.sequence - b.sequence)
    .map(
      (d): ICodeDescription => ({
        code: d.code,
        description: mapDescription(d),
      })
    );
}

// For API that does not return sequence and active flags
export function mapAllCodeDescriptions(
  desc: IAPICodeDescription[]
): ICodeDescription[] {
  return desc.map(
    (d): ICodeDescription => ({
      code: d.code,
      description: mapDescription(d),
    })
  );
}

export interface IAPIColorCodeDescription {
  tpShow: boolean;
  active: boolean;
  code: string;
  description: string;
  descriptionZhHK: string;
  descriptionZhCN: string;
  sequence: number;
  labelColour: string;
}
function isIAPIColorCodeDescription(
  d: IAPIColorCodeDescription | IColorCodeDescription
): d is IAPIColorCodeDescription {
  return typeof d.description === 'string';
}
export function mapColorCodeDescription(
  desc: IAPIColorCodeDescription | IColorCodeDescription
): IColorCodeDescription {
  if (isIAPIColorCodeDescription(desc)) {
    return {
      code: desc.code,
      description: mapDescription(desc),
      color: desc.labelColour,
    };
  }
  const { code, description, color } = desc;
  return {
    code,
    description,
    color: color ?? (desc as any).tpColor,
  };
}

export function mapColorCodeDescriptions(
  desc: IAPIColorCodeDescription[]
): IColorCodeDescription[] {
  return desc
    .filter((d) => d.tpShow && d.active)
    .sort((a, b) => a.sequence - b.sequence)
    .map(mapColorCodeDescription);
}

export function mapFieldState(s: APIFieldState): FieldState {
  switch (s) {
    case APIFieldState.MANDATORY:
      return FieldState.MANDATORY;
    case APIFieldState.DISPLAY:
      return FieldState.DISPLAY;
    case APIFieldState.HIDDEN:
      return FieldState.HIDDEN;
  }
  throw new Error('unknown field state');
}

export function mapAuthSubmissionGroup(
  submissionGroup: IAPIAuthSubmissionGroup
): IAuthSubmissionGroup {
  return {
    companyID: submissionGroup.companyID,
    companyName: submissionGroup.companyName,
    buildingNames: submissionGroup.buildingNames ?? [],
    groupID: submissionGroup.submissionGroupID,
    groupName: submissionGroup.submissionGroupName,
    buildingIDs: submissionGroup.buildingIDs,
    status: submissionGroup.status,
    effectiveDate: submissionGroup.effectiveMonth,
    expiryDate: submissionGroup.expiryMonth,
    isExpired: new Date() > submissionGroup.expiryMonth,
  };
}

export function mapRole(apiRole: IAPIRole): IRole {
  return {
    allowSetupInTP: apiRole.allowSetupInTP,
    code: apiRole.roleCode,
    description: {
      enUS: apiRole.description,
      zhHant: apiRole.descriptionZhHK || undefined,
      zhHans: apiRole.descriptionZhCN || undefined,
    },
    visibleInTP: apiRole.visibleInTP,
    aclType: apiRole.aclType,
  };
}

export function mapAuthSubmissionGroupRole(
  submissionGroupRole: IAPIAuthSubmissionGroupRole
): IAuthSubmissionGroupRole {
  return {
    gppSubmissionGroupID: submissionGroupRole.gppSubmissionGroupID,
    roles: submissionGroupRole.roles.map(mapRole),
  };
}

export function mapAuthUserSubmissionGroup(
  submissionGroupIdToRole: Map<number, IRole[]>,
  buildingIDTobuildingName: Map<number, string>,
  submissionGroup: IAuthSubmissionGroup
): IAuthUserSubmissionGroup {
  submissionGroup.buildingNames = submissionGroup.buildingIDs
    .map((id) => buildingIDTobuildingName.get(id) ?? null)
    .filter((buildingName) => buildingName);
  return {
    submissionGroup: submissionGroup,
    roles: submissionGroupIdToRole.get(submissionGroup.groupID) ?? [],
  };
}

export function mapBuildingFacility(
  dto: APIBuildingFacilityDTO
): BuildingFacility {
  return {
    code: dto.facilityType,
    description: {
      enUS: dto.description,
      zhHans: dto.descriptionZhCN,
      zhHant: dto.descriptionZhHK,
    },
    status:
      dto.status === 'ACTIVE'
        ? FacilityTypeStatus.Active
        : FacilityTypeStatus.Inactive,
    sequence: dto.sequence ?? 0,
  };
}

export function mapBuildingCommonFacility(
  buildingFloors: BuildingFloor[]
): (dto: APIBuildingCommonFacilityDTO) => BuildingCommonFacility {
  const buildingFloorIDs = buildingFloors.map((b) => b.id);
  const idToBuildingFloor = new Map(buildingFloors.map((b) => [b.id, b]));

  return (dto: APIBuildingCommonFacilityDTO) => {
    const isApplicableToAllFloors =
      dto.applicableAccessFloorIDs.length === 0 ||
      dto.applicableAccessFloorIDs.indexOf(-1) >= 0; // [-1] or empty array indicates all floors
    const applicableAccessFloorIDs = isApplicableToAllFloors
      ? buildingFloorIDs
      : dto.applicableAccessFloorIDs;
    return {
      id: dto.buildingCommonFacilityID,
      buildingFacility: mapBuildingFacility(dto.buildingFacility),
      statusCode: dto.status,
      facilityFloorIDs: dto.facilityFloors,
      facilityFloors: dto.facilityFloors
        .map((id) => idToBuildingFloor.get(id))
        .filter(isNonNullable),
      applicableAccessFloorIDs,
      isApplicableToAllFloors,
      defaultAutoAssignFloors: dto.defaultAutoAssign,
      autoAssignFloorIDs: (() => {
        const specificFloorIDsForAutoAssign = new Set(
          dto.specificFloorsForAutoAssign
        );
        if (dto.defaultAutoAssign) {
          return applicableAccessFloorIDs.filter(
            (id) => !specificFloorIDsForAutoAssign.has(id)
          );
        }
        return applicableAccessFloorIDs.filter((id) =>
          specificFloorIDsForAutoAssign.has(id)
        );
      })(),
    };
  };
}
